import { connect } from "react-redux";
import { Link} from "react-router-dom";
import React from "react";
import EmomItem from "../EmomItem/EmomItem";

function Emom(props) {

	return (
		<div className="Workout__superset-list">
			{props.set.exercises.map(function(exercise, index){
				return <EmomItem exercise={exercise} minute={index+1} key={index} set={props.set.id}/>				
				})
			}
		</div>
	);
}
const mapStateToProps = null

const mapDispatchToProps = null

export default connect(mapStateToProps, mapDispatchToProps)(Emom);
