import arrow from "./../../../img/back.svg";
import check from "./../../../img/check.svg";
import pause from "./../../../img/small-pause.svg";
import resume from "./../../../img/small-resume.svg";
import next from "./../../../img/next.svg";

import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import useDidMountEffect from "../../../helpers/useDidMountEffect";
import { parseTime } from "../../../helpers/parseTime";
import { SET_FULLBODY_STATUS, SET_TIMER, START_FULLBODY, START_TIMER, STOP_FULLBODY, STOP_TIMER} from "../../../constants/actionTypes";
import Video from "../../Video/Video";

function ItemRest(props) {
	
	const exercise = props.exercise;
	const linkToPreviousPage = `/body`;
	const linkToNextExercise = `/body/${props.set.id}/${props.nextExercise}`;
	const linkToInfo = `/body/${props.set.id}/${exercise.id}/info`;

	const navigate = useNavigate();	

    function goBack(){
		clearInterval(restTimerId);
        navigate(linkToPreviousPage);
    }	
	function goInfo(){
		navigate(linkToInfo);
	}
	const [state, setState] = useState({
		timer: props.timer,
		printTimer: parseTime(props.timer),
		isStart: props.isStart
	});

	const [timerId, setTimerId] = useState(null);

	const [stateRest, setStateRest] = useState({
		timer: exercise.status.reps[exercise.status.current].timer,
		printTimer: parseTime(exercise.status.reps[exercise.status.current].timer),
		isStop: true
	});	

	const [restTimerId, setRestTimerId] = useState(null);

	const [status, setStatus] = useState(exercise.status);

	function startTimer(){
		setTimerId(setInterval(() => {
			let newTimer = --state.timer;
			if(newTimer >= 0){
				setState({
					...state,
					timer: newTimer,
					printTimer: parseTime(newTimer)
				})
			}
		}, 1000))
    }
	
	useEffect(() => {
		document.title = props.exercise.name;
		if(!exercise.status.isComplete){
			props.onStartExercise()
		}
		if(status.isRest === false && !exercise.status.isComplete){
			props.onStartTimer();
			startTimer()
		}
			
		return () => {
			clearInterval(timerId);
			props.onSetTimer(state.timer);
		}
	}, []);

	function startRestTimer(){
		setRestTimerId(setInterval(() => {
			let newTimer = --stateRest.timer;
			if(newTimer >= 0){
				setStateRest({
					...stateRest,
					timer: newTimer,
					printTimer: parseTime(newTimer),
					isStop: false
				})
				setStatus({
					...status,
					isRest: true,
					reps: status.reps.map((item, index) => {
						if(status.current === index){
							item.currentTimer = newTimer;
							item.activeRest = true;
						}
						return item;
					})
				})
			} else {
				endRest();
			}
		}, 1000))
    }

	function goRest() {
		props.onStopTimer();
		clearInterval(timerId);
		setStatus({
			...status,
			isRest: true,
			reps: status.reps.map((item, index) => {
				if(status.current === index){
					item.activeRest = true;
				}
				return item;
			})
		})
		setStateRest({
			...stateRest,
			timer: status.reps[status.current].timer,
			printTimer: parseTime(status.reps[status.current].timer),
			isStop: false
		})
		startRestTimer();
	}

	function stopRest(){
		clearInterval(restTimerId);
		setStateRest({
			...stateRest,
			isStop: true
		})
	}
	
	function resumeRest(){
		setStateRest({
			...stateRest,
			isStop: false
		});
		startRestTimer();
	}	

	function endRest(){
		props.onStartTimer();
		clearInterval(restTimerId);
		let current = status.current;
		if(current + 1 < status.reps.length){
			setStatus(
				{
				...status,
				isRest: false,
				current: current+1,
				reps: status.reps.map((item, index) => {
					if(current + 1 === index){
						item.activeRep = true;
					}
					return item;
				})
			});
			setStateRest({
				...stateRest,
				timer: status.reps[current+1].timer,
				printTimer: parseTime(status.reps[current+1].timer),
				isStop: true
			})
		} else {
			setStatus(
				{
				...status,
				isRest: false,
				current: current,
				isComplete: true,
				reps: status.reps.map((item, index) => {
					if(current === index){
						item.activeRest = true;
					}
					return item;
				})
			});
			setStateRest({
				...stateRest,
				isStop: true
			})
		}
	}
	function skipRest(){
		clearInterval(timerId);
		let current = status.current + 1;
		if(current < status.reps.length){
			setStatus(
				{
				...status,
				isRest: false,
				current: current,
				reps: status.reps.map((item, index) => {
					if(current === index){
						item.activeRep = true;
					}
					if(current - 1 === index){
						item.activeRest = true;
					}
					return item;
				})
			});
		} else {
			setStatus(
				{
				...status,
				isRest: false,
				current: current - 1,
				isComplete: true,
				reps: status.reps.map((item, index) => {
					if(current - 1 === index){
						item.activeRest = true;
					}
					return item;
				})
			});
		}
	}

	function goNext(){
		clearInterval(restTimerId);
		if(props.isComplete && status.isComplete){
			props.onStopTimer();
			navigate(linkToPreviousPage);
			}
		else
        	navigate(linkToNextExercise);
	}

	useDidMountEffect(() => {
		props.onSetStatus(exercise.id,props.set.id, status);
	}, [status.isRest]);

	useDidMountEffect(() => {
		props.onSetStatus(exercise.id,props.set.id, status);
		if(!props.isComplete || !status.isComplete)
			navigate(linkToNextExercise);
	}, [status.current, status.isComplete]);

	useDidMountEffect(() => {
		props.onSetTimer(state.timer);
	}, [state.timer]);

	return (
		<>
			<img src={arrow} alt="arrow" className="go-Back" onClick={goBack}/>
			{exercise.video && exercise.video !== "" && <Video src={exercise.video} title={exercise.name}/>}
			
			<div className="Item-body">
				<div className="Item-top">
					<h2 className="Item-title">{exercise.name}</h2>
					<p className="Item-timeOut">
						{state.printTimer.minutes}:{state.printTimer.seconds}
					</p>	
				</div>
				<div className="Item-description" onClick={goInfo}>Instruction <img src={next} alt="next"/></div>
				<div>
				{status.reps.map((set, index) => {
					return(
						<div key={index}  className="Item-process">
							
							{(set.activeRep)
							? <>
								<div className="Item-step active">
									{(set.activeRest)
									? <img src={check} alt="check"/>
									: <span>{index + 1}</span>
									}
								</div>
								<div className="Item-reps active">{exercise.reps} {exercise.reps == 1 ? <>Rep</> : <>Reps</>}</div>
							</>
							:<>
								<div className="Item-step">
									<span>{index + 1}</span>
								</div>	
								<div className="Item-reps">{exercise.reps} {exercise.reps == 1 ? <>Rep</> : <>Reps</>}</div>
							</>
							}
							<div className="Item-Zero"></div>
							<div></div>
							{(set.activeRest && index == status.current && !status.isComplete)
							?<>
								<div className="Item-rest active"></div>
								<div className="Item-rest-time active">
									<div className="Item-rest-time-title">Rest</div>
									<div className="Item-rest-time-duration">{Object.values(parseTime(set.timer)).join(":")}</div>
								</div>
							</>
							:<>
								<div className="Item-rest"></div>
								<div className="Item-rest-time">
									<div className="Item-rest-time-title">Rest</div>
									<div className="Item-rest-time-duration">{Object.values(parseTime(set.timer)).join(":")}</div>
								</div>
							</>
							}
							
							{ (index+1 !== status.reps.length) 
							? <>
								<div className="Item-Zero"></div>
								<div></div>
								</>
							: <div></div>
							}
							
						</div>
					)					
				})
                }
				</div>
			</div>
			{(status.isRest === false)
			? ((status.isComplete) 
				? <button className="go-Next" onClick={goNext}>Done</button> 
				: ((stateRest.timer == 0)
					? <button className="go-Next" onClick={skipRest}>Log Set + Next Exercise</button>
					: <button className="go-Next" onClick={goRest}>Log Set + Rest</button>
				)
			)
			: <>
				<div className="Cardio-rest-timer">
					<div className="Cardio-rest-timer-top">
						<div className="Cardio-rest-timer-top-title">Rest</div>
						<div className="Cardio-rest-timer-top-timer">
							{stateRest.printTimer.minutes}:{stateRest.printTimer.seconds}
						</div>
					</div>
					<div className="Cardio-rest-timer-buttons">
							{(stateRest.isStop)
							? <button className="Cardio-rest-timer-button Cardio-rest-timer-pause" onClick={resumeRest}>
								<img src={resume} alt="resume"/> Resume
							</button>
							: <button className="Cardio-rest-timer-button Cardio-rest-timer-pause" onClick={stopRest}>
								<img src={pause} alt="pause"/> Pause
							</button>
							}
						<button className="Cardio-rest-timer-button Cardio-rest-timer-end" onClick={endRest}>
							End Rest
						</button>
					</div>
				</div>			
			</>
			}
		</>
	);
}

const mapStateToProps = state =>{
    return {
        timer: state.exercise.timer,
		isStart: state.exercise.isStart
    }    
}

const mapDispatchToProps = dispatch => ({
    onSetTimer: (timer) => {
        dispatch({
            type: SET_TIMER, timer
        })
    },
    onSetStatus: (id, set, status) => {
        dispatch({
            type: SET_FULLBODY_STATUS, id, set, status
        })
    },
	onStartExercise: () => {
        dispatch({
            type: START_FULLBODY
        })
    },
	onStartTimer: () => {
        dispatch({
            type: START_TIMER
        })
    },
	onStopTimer: () => {
        dispatch({
            type: STOP_TIMER
        })
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemRest);
