import React from "react";

import check from "./../../../img/check.svg";
import statusImage from "./../../../img/status.svg";
import videoArrow from "./../../../img/video-arrow.svg";
import  { useEffect } from "react";
import { Link} from "react-router-dom";

function EmomItem(props) {
    useEffect(() => {
		document.title = props.exercise.name;
	}, []);
    const properties = [];
    if(props.exercise["reps"]){
        if(props.exercise["reps"] != 1) 
            properties.push(`${props.exercise["reps"]} reps`)
    }
    if(props.exercise["weight"])
        properties.push(props.exercise["weight"])

    if(props.exercise["equipment"])
        properties.push(props.exercise["equipment"])

    if(props.exercise.status.isComplete){
        return (
            <>
                <div className="Exercises-section-title">{props.minute} minute</div>
                <div className="Exercises-item">
                    <div className="Exercises-no_link">
                        <img src={props.exercise.image} alt={props.exercise.name} className="Exercises-image"/>
                        <div className="Exercises-right">
                            <p className="Exercises-name">{props.exercise.name}</p>
                            {props.exercise.status.reps.length !== 1 &&
                            <p className="Exercises-complete">{props.exercise.status.reps.length}/{props.exercise.status.reps.length} logged</p>
                            }
                        </div>
                        <div className="Exercises-state">
                            <div className="Exercises-status">
                                <img src={check} alt="check" />
                            </div>
                        </div>
                    </div>
                </div>
            </>
		);
    } else {
		let complete = props.exercise.status.reps.filter(item => item.activeRest === true).length;
		let all = props.exercise.status.reps.length;
		let percent = ~~(complete/all*100);
		return (
            <>
                <div className="Exercises-section-title">{props.minute} minute</div>
                <div className="Exercises-item">
                    <div className="Exercises-no_link">
                        <img src={props.exercise.image} alt={props.exercise.name} className="Exercises-image"/>
                        <div className="Exercises-right">
                            <p className="Exercises-name">{props.exercise.name}</p>
                            <p className="Exercises-complete">{complete}/{all} logged</p>
                        </div>
                        <div className="Exercises-state">
                            <div className="Exercises-status">
                                <div className="Exercises-progress" style={{"--p":percent}}></div>
                                <img src={statusImage} alt="status" />
                            </div>
                        </div>
                    </div>
                </div>
            </>
		);        
    }
}

export default EmomItem;