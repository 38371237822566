
export const randomArray = (range, count) => {
    let m = {};
    let a = [];
    for (let i = 0; i < count; ++i) {
        let r = Math.floor(Math.random() * (range - i));
        a.push(((r in m) ? m[r] : r) + 1);
        let l = range - i - 1;
        m[r] = (l in m) ? m[l] : l;
    } 

    return a.map(x => x-1);
}