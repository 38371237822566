import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import ItemNoRest from "./ItemNoRest";
import ItemRest from "./ItemRest";

function MuscleItem(props) {

	let {id} = useParams();
	
	const supersetList = props.exercises.filter(list => list.some(item => item.id == id))[0];
	const exercise = supersetList.filter(item => item.id == id)[0];
	const currentIndex = supersetList.findIndex(item => item.id == id);
	const isLast = (currentIndex === supersetList.length - 1) ? true : false;
	let nextExercise = (isLast) ? supersetList[0].id : supersetList[currentIndex+1].id;
	let isSetComplete = (supersetList.reduce((result, item , index) => {
		if(index != supersetList.length - 1)
			return result + item.status.isComplete;
		else 
			return result;
	}, 0) === supersetList.length - 1) ? true : false;
	return (
		<>
			{isLast 
			? <ItemRest exercise={exercise} nextExercise={nextExercise} isComplete={isSetComplete}/>
			: <ItemNoRest exercise={exercise} nextExercise={nextExercise}/>
			}
			
		</>
	);
}

const mapStateToProps = state => {
    return {
        exercises: state.exercise.selectedMuscleExercises
    }    
}

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(MuscleItem);
