import { connect } from "react-redux";
import { useParams } from 'react-router-dom';
import Round from "./Round/Round";
import Single from "./Single/Single";

function FullBodyItem(props) {

	let {setId,elementId} = useParams();
	const set = props.exercise.sets.find(item => item.id == setId);
	const exercise = set.exercises.find(item => item.id == elementId);

	if(set["sets-count"] > 1){
		return <Round set={set} exercise={exercise} isStart={props.exercise.isStart}/>
	} else {
		return <Single set={set} exercise={exercise} isStart={props.exercise.isStart} />
	}
}

const mapStateToProps = state =>{
    return {
        exercise: state.exercise.selectedFullBodyExercises
    }    
}

const mapDispatchToProps = null;
export default connect(mapStateToProps, mapDispatchToProps)(FullBodyItem);
