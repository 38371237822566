import arrow from "./../../../img/back.svg";
import check from "./../../../img/check.svg";
import next from "./../../../img/next.svg";

import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { SET_FULLBODY_STATUS, SET_TIMER, START_FULLBODY, START_TIMER, STOP_FULLBODY, STOP_TIMER} from "../../../constants/actionTypes";
import useDidMountEffect from "../../../helpers/useDidMountEffect";
import { parseTime } from "../../../helpers/parseTime";
import Video from "../../Video/Video";

function ItemNoRest(props) {
	const exercise = props.exercise;
	const linkToPreviousPage = `/body`;
	const linkToNextExercise = `/body/${props.set.id}/${props.nextExercise}`;
	const linkToInfo = `/body/${props.set.id}/${exercise.id}/info`;

	const navigate = useNavigate();	

	const [state, setState] = useState({
		timer: props.timer,
		printTimer: parseTime(props.timer)
	});

	const [timerId, setTimerId] = useState(null);

    function goBack(){
        navigate(linkToPreviousPage);
    }	
	function goInfo(){
		navigate(linkToInfo);
	}
	const [status, setStatus] = useState(exercise.status);

	function startTimer(){
		setTimerId(setInterval(() => {
			let newTimer = --state.timer;
			if(newTimer >= 0){
				setState({
					...state,
					timer: newTimer,
					printTimer: parseTime(newTimer)
				})
			}
		}, 1000))
    }

	useEffect(() => {
		document.title = props.exercise.name;
		if(!exercise.status.isComplete){
			props.onStartExercise()
			props.onStartTimer();
			startTimer()
		}

		return () => {
			clearInterval(timerId);
			props.onSetTimer(state.timer);
		}
	}, []);

	function goNext(){
		clearInterval(timerId);
		let current = status.current + 1;
		if(current < status.reps.length){
			setStatus(
				{
				...status,
				isRest: false,
				current: current,
				reps: status.reps.map((item, index) => {
					if(current === index){
						item.activeRep = true;
					}
					if(current - 1 === index){
						item.activeRest = true;
					}
					return item;
				})
			});
		} else {
			setStatus(
				{
				...status,
				isRest: false,
				current: current,
				isComplete: true,
				reps: status.reps.map((item, index) => {
					if(current - 1 === index){
						item.activeRest = true;
					}
					return item;
				})
			});
		}
        
	}

	function done(){
		navigate(linkToNextExercise);
	}

	useDidMountEffect(() => {
		props.onSetTimer(state.timer);
	}, [state.timer]);

	useDidMountEffect(() => {
		props.onSetStatus(exercise.id,props.set.id, status);
		navigate(linkToNextExercise);
	}, [status.current, status.isComplete]);

	return (
		<>
			<img src={arrow} alt="arrow" className="go-Back" onClick={goBack}/>
			{exercise.video && exercise.video !== "" && <Video src={exercise.video} title={exercise.name}/>}
			

			<div className="Item-body">
				<div className="Item-top">
					<h2 className="Item-title">{exercise.name}</h2>
					<p className="Item-timeOut">
						{state.printTimer.minutes}:{state.printTimer.seconds}
					</p>
				</div>
				<div className="Item-description" onClick={goInfo}>Instruction <img src={next} alt="next"/></div>
				<div>
				{status.reps.map((set, index) => {
					return(
						<div key={index}  className="Item-process">
							
							{(set.activeRep)
							? <>
								<div className="Item-step active">
									{(set.activeRest)
									? <img src={check} alt="check"/>
									: <span>{index + 1}</span>
									}
								</div>
								<div className="Item-reps active">{exercise.reps} {exercise.reps == 1 ? <>Rep</> : <>Reps</>}</div>
							</>
							:<>
								<div className="Item-step">
									<span>{index + 1}</span>
								</div>	
								<div className="Item-reps">{exercise.reps} {exercise.reps == 1 ? <>Rep</> : <>Reps</>}</div>
							</>
							}
							
							{(index+1 !== status.reps.length) && 
							<>
								<div className="Item-Zero"></div>
								<div></div>
								<div className="Item-rest"></div>
								<div className="Item-rest-time">
									<div className="Item-rest-time-title">Rest</div>
									<div className="Item-rest-time-duration">00:00</div>
								</div>
								<div className="Item-Zero"></div>
								<div></div>
							</>
							}
							
						</div>
					)					
				})
                }
				</div>
			</div>
			{
			((status.isComplete) 
				? <button className="go-Next" onClick={done}>Done</button> 
				: <button className="go-Next" onClick={goNext}>Log Set + Next Exercise</button>
			)
			}
		</>
	);
}

const mapStateToProps = state =>{
    return {
        timer: state.exercise.timer,
		isStart: state.exercise.isStart
    }    
}

const mapDispatchToProps = dispatch => ({
    onSetTimer: (timer) => {
        dispatch({
            type: SET_TIMER, timer
        })
    },
    onSetStatus: (id, set, status) => {
        dispatch({
            type: SET_FULLBODY_STATUS, id, set, status
        })
    },
	onStartExercise: () => {
        dispatch({
            type: START_FULLBODY
        })
    },
	onStartTimer: () => {
        dispatch({
            type: START_TIMER
        })
    },
	onStopTimer: () => {
        dispatch({
            type: STOP_TIMER
        })
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemNoRest);
