import { connect } from "react-redux";
import ItemNoRest from "./ItemNoRest";
import ItemRest from "./ItemRest";

function Round(props) {
	
	const currentIndex = props.set.exercises.findIndex(item => item.id == props.exercise.id);
	const isLast = (currentIndex === props.set.exercises.length - 1) ? true : false;
	let nextExercise = (isLast) ? props.set.exercises[0].id : props.set.exercises[currentIndex+1].id;
	let isSetComplete = (props.set.exercises.reduce((result, item , index) => {
		if(index != props.set.exercises.length - 1)
			return result + item.status.isComplete;
		else 
			return result;
	}, 0) === props.set.exercises.length - 1) ? true : false;
	return (
		<>
			{isLast 
			? <ItemRest exercise={props.exercise} set={props.set} nextExercise={nextExercise} isComplete={isSetComplete} isStart={props.isStart} key={props.set.id*10 + props.exercise.id}/>
			: <ItemNoRest exercise={props.exercise} set={props.set} nextExercise={nextExercise} isStart={props.isStart} key={props.set.id*10 + props.exercise.id}/>
			}
			
		</>
	);
}

const mapStateToProps = null

const mapDispatchToProps = null

export default connect(mapStateToProps, mapDispatchToProps)(Round);
