import "./App.css";

import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "../Home/Home";
import OptionTime from "../OptionTime/OptionTime";
import OptionFocus from "../OptionFocus/OptionFocus";
import OptionGoal from "../OptionGoal/OptionGoal";
import OptionVibe from "../OptionVibe/OptionVibe";
import { LOAD_DATA } from "../../constants/actionTypes";
import MuscleStart from "../MuscleStart/MuscleStart";
import MuscleList from "../MuscleList/MuscleList";
import MuscleItem from "../MuscleItem/MuscleItem";
import MuscleFinish from "../MuscleFinish/MuscleFinish";
import Cardio from "../Cardio/Cardio";
import FullBody from "../FullBody/FullBody";
import FullBodyItem from "../FullBodyItem/FullBodyItem";
import MuscleItemInfo from "../MuscleItemInfo/MuscleItemInfo";
import FullBodyItemInfo from "../FullBodyItemInfo/FullBodyItemInfo";
import FullBodyFinish from "../FullBodyFinish/FullBodyFinish";
import CardioFinish from "../Cardio/CardioFinish";

function App(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    window.history.replaceState("", "", "/");
    names();
  }, []);

  const names = async () => {
    const responce = await fetch("#API_URL#");
    setData(await responce.json());
  };
  useEffect(() => {
    if (data.length > 0) {
      props.onLoadData(data);
    }
  }, [data]);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/time" element={<OptionTime />}></Route>
          <Route exact path="/focus" element={<OptionFocus />}></Route>
          <Route exact path="/goal" element={<OptionGoal />}></Route>
          <Route exact path="/vibe" element={<OptionVibe />}></Route>
          <Route exact path="/muscle-start" element={<MuscleStart />}></Route>
          <Route exact path="/muscle-finish" element={<MuscleFinish />}></Route>
          <Route exact path="/muscle-list" element={<MuscleList />}></Route>
          <Route
            exact
            path="/muscle-list/:id/info"
            element={<MuscleItemInfo />}
          ></Route>
          <Route exact path="/muscle-list/:id" element={<MuscleItem />}></Route>
          <Route exact path="/cardio" element={<Cardio />}></Route>
          <Route exact path="/cardio-finish" element={<CardioFinish />}></Route>
          <Route exact path="/body" element={<FullBody />}></Route>
          <Route
            exact
            path="/body/:setId/:elementId/info"
            element={<FullBodyItemInfo />}
          ></Route>
          <Route
            exact
            path="/body/:setId/:elementId"
            element={<FullBodyItem />}
          ></Route>
          <Route exact path="/body-finish" element={<FullBodyFinish />}></Route>
          <Route exact path="*" element={<Home />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  onLoadData: (data) => {
    dispatch({
      type: LOAD_DATA,
      data,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
