import { connect } from "react-redux";

import React from "react";
import AmrapItem from "../AmrapItem/AmrapItem";

function Amrap(props) {

	return (
		<>	
			<div className="Workout__superset-list">
				{props.set.exercises.map(function(exercise, index){
					return <AmrapItem exercise={exercise} key={index} set={props.set.id}/>				
					})
				}
			</div>
		</>
	);
}
const mapStateToProps = null

const mapDispatchToProps = null

export default connect(mapStateToProps, mapDispatchToProps)(Amrap);
