import arrow from "./../../img/go_back.svg";
import comment from "./../../img/comment.svg";
import pause from "./../../img/pause.svg";
import start from "./../../img/resume.svg";

import { connect } from "react-redux";
import React, { useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';

import { parseTime } from "../../helpers/parseTime";
import { SET_TIMER, START_FULLBODY, START_TIMER, STOP_FULLBODY, STOP_TIMER} from "../../constants/actionTypes";

import Amrap from "./Amrap/Amrap";
import Emom from "./Emom/Emom";
import useDidMountEffect from "../../helpers/useDidMountEffect";

function FullBody(props) {

	const linkToPreviousPage = `/focus`;
	const linkToNextPage = `/body-finish`;
	const navigate = useNavigate();	

	const [state, setState] = useState({
		timer: props.timer,
		printTimer: parseTime(props.timer),
		isStop: !props.exercise.isStart,
		isStart: false
	});

	const [timerId, setTimerId] = useState(null);
	
	useEffect(() => {
		document.title = 'Full body exercises';
		if(props.isStart)
			startTimer();

		return () => {
			clearInterval(timerId);
			props.onSetTimer(state.timer);
		}
	}, []);

    function goBack(){
		props.onStopTimer();
        navigate(linkToPreviousPage);
    }
 	function goNext(){
		props.onStopExercise();
		props.onStopTimer();
        navigate(linkToNextPage);
    }
	function startTimer(){
		setTimerId(setInterval(() => {
			let newTimer = --state.timer;
			if(newTimer >= 0){
				setState({
					...state,
					timer: newTimer,
					printTimer: parseTime(newTimer),
					isStop: false,
					isStart: true
				})
			}
		}, 1000))
    }

	function startExercise(){
		props.onStartExercise();
	}

	function stop(){
		clearInterval(timerId);
		props.onSetTimer(state.timer);
		props.onStopTimer()
		setState({
			...state,
			isStop: true
		})
		
	}
	
	function resume(){
		setState({
			...state,
			isStop: false
		})
		startTimer()
		props.onStartExercise();
		props.onStartTimer();
	}	

	useDidMountEffect(() => {
		props.onSetTimer(state.timer);
	}, [state.timer]);

	return (
		<>
			<img src={arrow} alt="arrow" onClick={goBack} className="go-Back"/>
			<div className="Exercises-body Exercises-body_fullbody">
				<p className="Exercises-clock Exercises-clock_fullbody">
					<span className="Exercises-time">{state.printTimer.minutes}</span>
					<span className="Exercises-dot">:</span>
					<span className="Exercises-minutes">{state.printTimer.seconds}</span>
				</p>
				{!props.isStart
				? <img className="Exercises-pause" alt="resume" src={start} onClick={resume}/>
				: <img className="Exercises-pause" alt="pause" src={pause} onClick={stop}/>
				}
				<h3 className="Exercises-title_fullbody">{props.exercise.title}</h3>
				<p className="Exercises-title_description">{props.exercise.type}</p>
				<div className="Exercises-container">
					{props.exercise.sets.map((set, index) => {
						if(set.set_type === "EMOM"){
							return 	<div className="Workout__superset" key={index}>
										<div className="Workout__superset-title">
											Superset
											{(set["sets-count"] > 1) &&
											<> &bull; {set["sets-count"]} Rounds</>}
										</div>
										<Emom set={set}/>
									</div>
						}else{
							return 	<div className="Workout__superset" key={index}>
										<div className="Workout__superset-title">
											Superset
											{(set["sets-count"] > 1) &&
											<> &bull; {set["sets-count"]} Rounds</>}
										</div>
										<Amrap set={set}/>
									</div> 	
						}
						
					})	
					}
					{props.exercise.comment !== "" && 
					<div className="Exercise-comment">
						<img src={comment} alt="comment" />
						<p className="Exercise-comment-value">{props.exercise.comment}</p>
					</div>
					}
				</div>
				{props.exercise.isStart
				? <button className="go-Next" onClick={goNext}>Finish Workout</button>
				: <button className="go-Next" onClick={startExercise}>Start</button>
				}
			</div>
			
		</>
	);
}
const mapStateToProps = state =>{
    return {
        exercise: state.exercise.selectedFullBodyExercises,
        timer: state.exercise.timer,
		isStart: state.exercise.isStart
    }    
}

const mapDispatchToProps = dispatch => ({
    onSetTimer: (timer) => {
        dispatch({
            type: SET_TIMER, timer
        })
    },
	onStartExercise: () => {
        dispatch({
            type: START_FULLBODY
        })
    },
	onStopExercise: () => {
        dispatch({
            type: STOP_FULLBODY
        })
    },
	onStartTimer: () => {
        dispatch({
            type: START_TIMER
        })
    },
	onStopTimer: () => {
        dispatch({
            type: STOP_TIMER
        })
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(FullBody);
