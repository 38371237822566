import React from "react";
import { connect } from "react-redux";
import { useNavigate, useParams} from 'react-router-dom';
import Video from "../Video/Video";
import { useEffect } from "react";

function MuscleItemInfo(props) {

	useEffect(() => {
        document.title = props.exercise.title;
    }, []);
	
	const navigate = useNavigate();	

    let {id} = useParams();
    const linkToPreviousPage = `/muscle-list/${id}`;
    const supersetList = props.exercises.filter(list => list.some(item => item.id == id))[0];
	const exercise = supersetList.filter(item => item.id == id)[0];

    function goBack(){
        navigate(-1);
    }

	return (
		<>
			{exercise.video !== "" && 
				<Video src={exercise.video} title={exercise.title}/>
			}
			<div className="Cardio-body">
				{exercise.video !== "" 
				? 
				<div className="Cardio-top">
					<p className="Cardio-tempo">Instruction</p>
					<div className="Cardio-top-title">
						<h2 className="Cardio-title">
							{exercise.title}
						</h2>
					</div>				
				</div>
				:
				<div className="Cardio-top Cardio-top_no-video">
					<p className="Cardio-tempo">Instruction</p>
					<div className="Cardio-top-title">
						<h2 className="Cardio-title">
							{exercise.title}
						</h2>
					</div>				
				</div>					
				}
				<div className="Cardio-equipment Cardio-equipment_item">
					<div className="Cardio-equipment_text">
						{exercise.description}
					</div>
				</div>
			</div>
			<button className="go-Next" onClick={goBack}>Back</button>
		</>
	);
}

const mapStateToProps = state => {
    return {
        exercises: state.exercise.selectedMuscleExercises
    }    
}

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(MuscleItemInfo);
