import img from './../../img/goal.png'
import arrow from './../../img/go_back.svg'

import {connect} from 'react-redux';
import { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import { SET_GOAL } from '../../constants/actionTypes';

function  OptionGoal(props){

    const [state, setState] = useState({
        disable: true,
        goalList: [
            {label: "Strength",checked: false,value: "Strength",id: "strength"},
            {label: "Muscle Gain & Toning",checked: false,value: "Muscle Gain & Toning",id: "muscle_gain"},
            {label: "Fat Burn",checked: false,value: "Fat Burning",id: "fat_burn"},
        ]
    });

    const linkToPreviousPage = `/focus`;
    const linkToNextPage = `/vibe`;
    const navigate = useNavigate();  

    useEffect(() => {
        document.title = 'Goal page';
        if(state.goalList.some(item => item.value === props.goal)){
            const newList = state.goalList.map(item => {
                    if(item.value === props.goal){
                        item.checked = true;
                    }
                    return item;
                })
            setState({...state, goalList: newList, disable: false})
            }
    },[]);

    function goBack(){
        navigate(linkToPreviousPage);
    }

    function goNext(){
        navigate(linkToNextPage);
    }

    function goalChange(e){
        const newList = state.goalList.map(item => {
                if(item.value === e.target.value){
                    item.checked = true;
                } else {
                    item.checked = false;
                }
                return item;
            });
        setState({...state, goalList: newList, disable: false});
        props.onChangeGoal(e.target.value);       
    }

    return(
        <>
        <img src={arrow} alt='arrow' onClick={goBack} className='go-Back'/>
        <div className="Goal-body">
            <div className="Goal-wrapper">    
                <h2 className='Goal-title'> What is your <br></br><span className="Goal-span span">main</span> goal?</h2>
                <h3 className='Goal-title_small'>You will get results in all of these goals, but please choose <span className='Goal-span_small'>one for today.</span></h3>
                <div className='Goal-selection'>
                    {
                        state.goalList.map((item, index) =>{
                            return(
                                <div className='Goal-select' key={index} >
                                    <input type='radio' name="goal" id={item.id} className="Goal-input"
                                    value={item.value} onChange={goalChange} checked={item.checked}/>
                                    <label htmlFor={item.id} className="Goal-label">{item.label}</label> 
                                </div>
                            )
                        })
                    } 
                </div>
            </div>
            <div className="Goal-image">
                 <img src={img} alt='time' className='img'/>   
            </div>
            <button className='go-Next' onClick = {goNext} disabled={state.disable}>Next</button>
        </div>    
        </>
    )
}
const mapStateToProps = state =>{
    return {
        goal: state.option.options.goal
    }    
}

const mapDispatchToProps = dispatch => ({
    onChangeGoal: (value) => {
        dispatch({
            type: SET_GOAL, value
        })
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(OptionGoal); 