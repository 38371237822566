import image from "./../../img/cardio-end.png";

import { connect } from "react-redux";
import React from "react";
import { useNavigate } from 'react-router-dom';
import { CLEAR_OPTION } from "../../constants/actionTypes";
import  { useEffect } from "react";

function CardioFinish(props) {
	useEffect(() => {
		document.title = 'Finish cardio workout';
	}, []);

	const linkToNextPage = `/`;
	const navigate = useNavigate();	


 	function goNext(){
		props.onClearOptions();
        navigate(linkToNextPage);
    }


	return (
		<>
			<div className="StartPage-body">
				<div>
					<h1 className="Exercises-bigtitle">Congrats!</h1>
					<h2 className='Exercises-title'>You are done with your workout!</h2>
				</div>
				<div className="Cardio-finish">
					<img src={image} alt="Cardio end" className="StartPage-img img"/>
				</div>
				<div></div>
			</div>
			<button className="go-Next" onClick={goNext}>Start New Workout</button>
		</>
	);
}
const mapStateToProps = state =>{
    return {
		options: state.option.options
    }    
}

const mapDispatchToProps = dispatch => ({
	onClearOptions: () => {
		dispatch({
			type: CLEAR_OPTION
		})
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(CardioFinish);
