import {
    SET_TIME,
    SET_FOCUS,
    SET_GOAL,
    SET_VIBE,
    CLEAR_OPTION
} from "../constants/actionTypes";

const initialState = {
    options: {
        time: "",
        exerciseType: "",
        exerciseValue: [],
        goal: "",
        vibe: ""
    }
}

export const option = (state = initialState, action) => {
    switch(action.type){
        case SET_TIME:
            return {
                ...state,
                options: {
                    ...state.options,
                    time: action.value
                }
            };
        case SET_FOCUS:
            return {
                ...state,
                options: {
                    ...state.options,
                    exerciseType: action.exerciseType,
                    exerciseValue: action.exerciseValue
                }
            };
        case SET_GOAL: 
            return {
                ...state,
                options: {
                    ...state.options,
                    goal: action.value
                }                
            };
        case SET_VIBE: 
            return {
                ...state,
                options: {
                    ...state.options,
                    vibe: action.value
                }                
            };
        case CLEAR_OPTION:{
            return {
                ...state,
                options: initialState
            }
        }
        default:
            return state;
    }
}