import arrow from "./../../img/go_back.svg";
import videoArrow from "./../../img/video-arrow.svg";

import { Link} from "react-router-dom";
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { SET_EXERCISES, SET_TIMER } from "../../constants/actionTypes";
import { useEffect } from "react";

function WorkoutStart(props) {
    useEffect(() => {
        document.title = 'Muscle start exercises list';
    }, []);

    const linkToPreviousPage = `/vibe`;
    const linkToNextPage = `/muscle-list`;
    const navigate = useNavigate();

    function goBack(){
        navigate(linkToPreviousPage);
    }

    function goNext(){
		props.onSetTimer(props.timer);
        navigate(linkToNextPage);
    }

	function shuffle(){
		props.onSelectExercise(props.options);
	}

	return (
		<>
			<img src={arrow} alt="arrow" onClick={goBack} className="go-Back Workout-go-Back"/>
			<div className="Workout-body">
				<h2 className="Workout-title">
					Great, here's<br></br>
					<span className="Workout-span span">today's workout! </span>
				</h2>
				<p className="Goal-title_small">For each movement, choose a challenging weight where you would only be able to do 1-2 more reps than what is shown below.</p>
				<h3 className="Workout-title_small">Exercises</h3>
				<div className="Workout-container">
					{props.exercises.map(function (list, index) {
						return (
							<div className="Workout__superset" key={index}>
								<div className="Workout__superset-title">
									Superset {index+1}
								</div>
								<div className="Workout__superset-list">
									{list.map(function (exercise, key){
										return (
											<div className="Workout-item" key={key}>
												<Link to={`/muscle-list/${exercise.id}/info`}>
													<img src={exercise.image} alt="video" className="Exercises-image"/>
													<div className="Workout-right">
														<p className="Workout-name">{exercise.title}</p>
														<p className="Workout-exercise">
															<span>{exercise?.reps_sets.sets} sets</span> . <span>{exercise?.reps_sets.reps + " reps"}</span>
														</p>
														<p className="Workout-tempo">{exercise?.tempo.tempo_title}</p>
													</div>
													<div className="Exercises-state">
														<div className="Exercises-status">
															<img src={videoArrow} alt="video-arrow" className="Exercises-arrow"/>
														</div>
													</div>
												</Link>
											</div>
										);
									})}
								</div>
							</div>
						);
					})}
				</div>
				<button className="shuffle" onClick={shuffle}>
					Generate a new workout
				</button>
				<button className="go-Next" onClick={goNext}>
					Start Workout
				</button>
			</div>
		</>
	);
}
const mapStateToProps = state =>{
    return {
        exercises: state.exercise.selectedMuscleExercises,
		timer: state.option.options.time,
		exerciseValue: state.option.options.exerciseValue,
        options: state.option.options
    }    
}

const mapDispatchToProps = dispatch => ({
    onSetTimer: (timer) => {
        dispatch({
            type: SET_TIMER, timer
        })
    },
    onSelectExercise: (options) => {
        dispatch({
            type: SET_EXERCISES, options
        })
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(WorkoutStart);
