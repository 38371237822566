import arrow from "./../../../img/back.svg";

import React, { useEffect, useState} from "react";
import { connect } from "react-redux";
import { useNavigate} from 'react-router-dom';

import useDidMountEffect from "../../../helpers/useDidMountEffect";
import { parseTime } from "../../../helpers/parseTime";
import { SET_FULLBODY_STATUS, SET_TIMER, START_FULLBODY, START_TIMER, STOP_FULLBODY, STOP_TIMER} from "../../../constants/actionTypes";

function Single(props) {

	const linkToPreviousPage = `/body`;
	const navigate = useNavigate();	
    const exercise = props.exercise;

	const [state, setState] = useState({
		timer: props.timer,
		printTimer: parseTime(props.timer),
		isStart: false
	});

	const [timerId, setTimerId] = useState(null);
	const [status, setStatus] = useState(exercise.status);

	useEffect(() => {
		document.title = props.exercise.name;
		if(props.isStart){
			startTimer()
		}
		return () => {
			clearInterval(timerId);
			props.onSetTimer(state.timer);
		}
	}, []);

    function goBack(){
        navigate(linkToPreviousPage);
    }

	function startTimer(){
		setTimerId(setInterval(() => {
			let newTimer = --state.timer;
			if(newTimer >= 0){
				setState({
					...state,
					timer: newTimer,
					printTimer: parseTime(newTimer),
					isStop: false,
					isStart: true
				})
			}
		}, 1000))
    }

	function startExercise(){
		if(!props.isStart){
			startTimer()
			props.onStartTimer()
		}
		props.onStartExercise()
		setStatus({
			...status,
			isStart: true
		})
	}

	function finishExercise(){
		setStatus({
			...status,
			isComplete: true
		})
		clearInterval(timerId);
		props.onStopTimer();
	}

	useDidMountEffect(() => {
		props.onSetStatus(exercise.id, props.set.id, status);
	}, [status]);

	useDidMountEffect(() => {
		props.onSetTimer(state.timer);
	}, [state.timer]);

	return (
		<>
			<img src={arrow} alt="arrow" onClick={goBack} className="go-Back"/>
			{exercise.video !== "" && <iframe width="100%" height="100%" src={exercise.video} title={exercise.title} className="Item-video"></iframe>}
			<div className="Cardio-body">
				{exercise.video !== "" 
				? 
				<div className="Cardio-top">
					<p className="Cardio-tempo">Instruction</p>
					<div className="Cardio-top-title">
						<h2 className="Cardio-title">
							{exercise.name}
						</h2>
                        <p className="Item-timeOut">{state.printTimer.minutes}:{state.printTimer.seconds}</p>
					</div>				
				</div>
				:
				<div className="Cardio-top Cardio-top_no-video">
					<p className="Cardio-tempo">Instruction</p>
					<div className="Cardio-top-title">
						<h2 className="Cardio-title">
							{exercise.name}
						</h2>
                        <p className="Item-timeOut">{state.printTimer.minutes}:{state.printTimer.seconds}</p>
					</div>				
				</div>					
				}
				<div className="Cardio-equipment Cardio-equipment_item">
					<div className="Cardio-equipment_text">
						{exercise.description}
					</div>
				</div>
			</div>
			{status.isComplete && 
				<button className="go-Next" onClick={goBack}>Back</button>
			} 
			{!status.isComplete && status.isStart && 
				<button className="go-Next" onClick={finishExercise}>Finish</button>
			}
			{!status.isComplete && !status.isStart && 
				<button className="go-Next" onClick={startExercise}>Start</button>
			}
		</>
	);
}

const mapStateToProps = state =>{
    return {
        timer: state.exercise.timer,
		isStart: state.exercise.isStart
    }    
}

const mapDispatchToProps = dispatch => ({
    onSetTimer: (timer) => {
        dispatch({
            type: SET_TIMER, timer
        })
    },
    onSetStatus: (id, set, status) => {
        dispatch({
            type: SET_FULLBODY_STATUS, id, set, status
        })
    },
	onStartExercise: () => {
        dispatch({
            type: START_FULLBODY
        })
    },
	onStartTimer: () => {
        dispatch({
            type: START_TIMER
        })
    },
	onStopTimer: () => {
        dispatch({
            type: STOP_TIMER
        })
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Single);
