export const SET_DATA = "SET_DATA";

//options
export const SET_TIME = "SET_TIME";
export const SET_FOCUS = "SET_FOCUS";
export const SET_GOAL = "SET_GOAL";
export const SET_VIBE = "SET_VIBE";
export const CLEAR_OPTION = "CLEAR_OPTION";
//common timer
export const SET_TIMER = "SET_TIMER";

//exercise timer
export const SET_EXERCISE_TIMER = "SET_EXERCISE_TIMER";
export const SET_REST_TIMER = "SET_REST_TIMER";
export const SET_STATUS = "SET_STATUS";
export const SET_COMPLETE = "SET_COMPLETE";
export const SET_FULLBODY_STATUS = "SET_FULLBODY_STATUS";
export const START_FULLBODY = "START_FULLBODY";
export const STOP_FULLBODY = "STOP_FULLBODY";
export const START_TIMER = "START_TIMER";
export const STOP_TIMER = "STOP_TIMER";

//timers
export const TIMER_15 = 900;
export const TIMER_30 = 1800;
export const TIMER_45 = 2700;

//exercise type
export const MUSCLE = "muscle-group";
export const CARDIO = "cardio";
export const FULL_BODY = "full-body";
export const AMRAP = "AMRAP";
export const EMOM = "EMOM";

//exercise
export const LOAD_DATA = "LOAD_DATA";
export const SET_EXERCISES = "SET_EXERCISES";
