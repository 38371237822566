import img from './../../img/time.png'
import arrow from './../../img/go_back.svg'

import {connect} from 'react-redux';
import { useEffect, useState} from 'react'; 
import { useNavigate } from 'react-router-dom';

import { TIMER_15, TIMER_30, TIMER_45, SET_TIME} from '../../constants/actionTypes';

function OptionTime(props){

    const [state, setState] = useState({
        disable: true,
        timeList: [
        {label: "15 minutes",checked: false,value: TIMER_15,id: "time_15"},
        {label: "30 minutes",checked: false,value: TIMER_30,id: "time_30"},
        {label: "45 minutes",checked: false,value: TIMER_45,id: "time_45"},
        ]
    });
    const linkToPreviousPage = `/`;
    const linkToNextPage = `/focus`;
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Time page';
        if(state.timeList.some( item => item.value === props.time)){
            const newList = state.timeList.map(item => {
                    if(item.value === props.time){
                        item.checked = true;
                    }
                    return item;
                })
            setState({...state, timeList: newList, disable: false})
            }
    },[]);

    function goBack(){
        navigate(linkToPreviousPage);
    }

    function goNext(){
        navigate(linkToNextPage);
    }

    function timeChange(e){
        const newList = state.timeList.map(item => {
                if(item.value === +e.target.value){
                    item.checked = true;
                } else {
                    item.checked = false;
                }
                return item;
            });
        setState({...state, timeList: newList, disable: false});
        props.onChangeTime(+e.target.value);       
    }

    return (
        <>
        <img src={arrow} alt='arrow' onClick={goBack} className='go-Back'></img>
        <div className="Time-body">
            <div className="Time-wrapper">           
                <h2 className="Time-title">
                    How much <span className="Time-span span">time</span><br></br> do you have?
                </h2>
                <div className='Time-selection'>
                    {
                    state.timeList.map((item, index) => {
                        return (
                            <div className='Time-select' key={index} >
                                <input type="radio" name="time" id={item.id} className="Time-input" value={item.value} onChange={timeChange} checked={item.checked}/>
                                <label htmlFor={item.id} className="Time-label">{item.label}</label> 
                            </div>
                        )
                    })
                    }
                </div>
            </div>
            <div className="Time-image">
                 <img src={img} alt='time' className='img'/>   
            </div>
            <button className='go-Next' onClick = {goNext} disabled={state.disable}>Next</button>
        </div>
        </>
    )
}
const mapStateToProps = state =>{
    return {
        time: state.option.options.time
    }    
}

const mapDispatchToProps = dispatch => ({
    onChangeTime: (value) => {
        dispatch({
            type: SET_TIME, value
        })
    }
});
  
export default connect(mapStateToProps, mapDispatchToProps )(OptionTime);