import arrow from "./../../img/back.svg";
import back from './../../img/go_back.svg';

import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import { parseTime } from "../../helpers/parseTime";
import { SET_TIMER, CLEAR_OPTION, START_TIMER, STOP_TIMER } from "../../constants/actionTypes";
import useDidMountEffect from "../../helpers/useDidMountEffect";
import Video from "../Video/Video";

function Cardio(props) {

	const linkToPreviousPage = `/focus`;
	const linkToNextPage = `/cardio-finish`;
	const navigate = useNavigate();	

    function goBack(){
		clearInterval(timerId);
        navigate(linkToPreviousPage);
    }
 	function goNext(){
		props.onStopExercise();
        navigate(linkToNextPage);
    }

	const [state, setState] = useState({
		timer: props.timer,
		fullTime: parseTime(props.time),
		printTimer: parseTime(props.timer),
		lastChange: 0	
	});

	const [timerId, setTimerId] = useState(null);

	const [status, setStatus] = useState({
		isStart: false,
		current: 0,
		currentRep: 0,
		reps: props.exercise.exercise_rest_counter,
		isRest: false,
		isComplete: false	
	});

	useEffect(() => {
		document.title = props.exercise.title;
		return () => {
			clearInterval(timerId);
			props.onSetTimer(state.timer);
		}
	}, []);

	function startTimer(){
		setTimerId(setInterval(() => {
			let newTimer;
			let currentDate = (new Date()).getTime();
			let diff = Math.floor((currentDate - state.lastChange)/1000);
			if(!state.lastChange || diff <= 3){
				newTimer = --state.timer;
			}
			else{ 
				newTimer = state.timer - diff;
			}
			if(newTimer >= 0){
				setState({
					...state,
					timer: newTimer,
					printTimer: parseTime(newTimer),
					lastChange: currentDate
				})
			}
		}, 1000))
    }

	function start(){
		setStatus({
			...status,
			isStart: true
		})
		props.onStartExercise();
		startTimer()
	}
	useDidMountEffect(() => {
		props.onSetTimer(state.timer);
	}, [state.timer]);
	
	return (
		<>	
			{props.exercise.exercise_video !== ""
			?	<>
					<img src={arrow} alt="arrow" onClick={goBack} className="go-Back"/>
					<Video src={props.exercise.exercise_video} title={props.exercise.title}/>
				</>
			:	<>
					 <img src={back} alt='arrow' onClick={goBack} className='go-Back'/>
				</>
			}
			<div className="Cardio-body">
				<div className="Cardio-top">
					<div className="Cardio-top-title">
						<h2 className="Cardio-title">
							{props.exercise.title}
						</h2>
						<p className="Item-timeOut">{state.printTimer.minutes}:{state.printTimer.seconds}</p>
					</div>
					<p className="Cardio-tempo">{state.fullTime.minutes} minutes</p>
				</div>
				<div className="Cardio-equipment">
					<div className="Cardio-equipment_title">Equipment</div>
					<div className="Cardio-equipment_text">
						{props.exercise.exercise_equipment ? props.exercise.exercise_equipment : ""}
					</div>
				</div>
				<div className="Cardio-tempos">
					<div className="Cardio-tempo_title">Tempo</div>
					<div className="Cardio-tempo_text">{props.exercise.exercise_tempo ? props.exercise.exercise_tempo : ""}</div>
				</div>
				<div className="Cardio-rest">
					<div className="Cardio-rest_title">Rest</div>
					<div className="Cardio-rest_text">{props.exercise.exercise_rest_description ? props.exercise.exercise_rest_description : ""}</div>
				</div>
			</div>
			{status.isStart === false && <button className="go-Next" onClick={start}>Start</button>}
			{status.isStart === true && <button className="go-Next" onClick={goNext}>Finish</button>} 
		</>
	);
}

const mapStateToProps = state =>{
    return {
        exercise: state.exercise.selectedCardioExercises,
        timer: state.exercise.timer,
		time: state.option.options.time
    }    
}

const mapDispatchToProps = dispatch => ({
    onSetTimer: (timer) => {
        dispatch({
            type: SET_TIMER, timer
        })
    },
	onClearOptions: () => {
		dispatch({
			type: CLEAR_OPTION
		})
	},
	onStartExercise: () => {
        dispatch({
            type: START_TIMER
        })
    },
	onStopExercise: () => {
        dispatch({
            type: STOP_TIMER
        })
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(Cardio);
