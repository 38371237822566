import {
    SET_EXERCISES, MUSCLE, CARDIO, FULL_BODY, LOAD_DATA, TIMER_15, TIMER_30, TIMER_45,
    SET_TIMER, SET_STATUS,
    SET_FULLBODY_STATUS,
    START_FULLBODY,
    STOP_FULLBODY,
    START_TIMER,
    STOP_TIMER
} from "../constants/actionTypes";

import {randomArray} from "../helpers/random";

const initialState = {
    currentType: MUSCLE,
    muscleExercises: [],
    cardioExercises: [],
    fullBodyExercises: [],
    selectedMuscleExercises: [],
    selectedCardioExercises: {},
    selectedFullBodyExercises: {},
    timer: 0,
    isStart: false,
    lastTimerUpdate: null
}

export const exercise = (state = initialState, action) => {
    switch(action.type){        
        case LOAD_DATA: 
            return {
                ...state,
                muscleExercises: action.data[0],
                cardioExercises: action.data[1],
                fullBodyExercises: action.data[2]
            }
        case SET_TIMER:
            if(state.lastTimerUpdate == null){
                return {
                     ...state,
                    timer: action.timer,
                    lastTimerUpdate: new Date()
                }
            }
            let currentDate = new Date();
            let timerDiff = action.timer - state.timer;
            let lastTimerUpdateDiff = Math.ceil((currentDate - state.lastTimerUpdate)/1000);
            if(Math.abs(timerDiff - lastTimerUpdateDiff) > 30 && state.isStart){
                return {
                    ...state,
                    timer: state.timer - lastTimerUpdateDiff,
                    lastTimerUpdate: currentDate
                }
            } else {
                return {
                    ...state,
                    timer: action.timer,
                    lastTimerUpdate: currentDate
                }
            }
        case SET_EXERCISES: 
            // eslint-disable-next-line default-case
            switch (action.options.exerciseType){
                case MUSCLE: {
                    //select exercises by app filter(goal, focus, type)
                    let exercises = state.muscleExercises.filter(item => {
                        let checkedGoal = item.goal_tempo.some(goal => goal.goal_name === action.options.goal);
                        //action.options.exerciseValue.includes
                        let checkedFocus = action.options.exerciseValue.includes(item.exercises);
                        return item.type === MUSCLE && checkedGoal && checkedFocus;
                    });
                    //divide exercises by focus and group with modification by focus
                    const focuses = action.options.exerciseValue.map(focus => {
                        let temp = exercises.filter(exercise => exercise.exercises === focus).map( exercise => {
                            let goal = exercise.goal_tempo.filter(goal => goal.goal_name === action.options.goal)[0];
                            let vibe = exercise.vibe.filter(item => item.vibe_number === action.options.vibe)[0];
                            vibe = {
                                    rest: vibe?.between_supertest,
                                    end: vibe?.end_supertest
                                };
                            let repSet = goal.reps_sets[randomArray(goal.reps_sets.length,1)[0]]
                            let tempo = goal.tempo[randomArray(goal.tempo.length,1)[0]]
                            
                            let status = {
                                isRest: false,
                                isComplete: false,
			                    current: 0,
                                reps: []
                            }
                            for(let i = 0; i < repSet.sets;i++){
                                let rep = {
                                    activeRep: (i === 0) ? true : false,
                                    activeRest: false,
                                    timer: (i < (repSet.sets- 1)) ? vibe.rest : vibe.end,
                                    currentTimer: (i < (repSet.sets - 1)) ? vibe.rest : vibe.end,
                                }
                                status.reps.push(rep);
                            }
                            return {
                                id: exercise.id,
                                title: exercise.title,
                                focus: exercise.exercises,
                                priority: exercise.exercise_priority,
                                description: exercise.exercise_description,
                                video: exercise.exercise_video,
                                image: exercise.exercise_image,
                                goal: goal.goal_name,
                                reps_sets: repSet,
                                tempo: tempo,
                                vibe: vibe,
                                status: status
                            }
                        })
                        //divide my priority
                        return [0,1,2].map(priority => temp.filter(item => (item.priority-1) == priority))
                    });
                    //use logic of count for selected focus
                    if(focuses.length === 1){
                        let rand0, rand1, rand2;
                        // eslint-disable-next-line default-case
                        switch(action.options.time){
                            case TIMER_15: 
                                rand0 = randomArray(focuses[0][0].length, 1);
                                rand1 = randomArray(focuses[0][1].length, 1);
                                return {
                                    ...state,
                                    currentType: MUSCLE,
                                    timer: action.options.time,
                                    selectedMuscleExercises: [
                                        focuses[0][0].filter((focus, index) => rand0.includes(index))
                                        .concat(focuses[0][1].filter((focus, index) => rand1.includes(index)))
                                        .map((item, index, array) => {
                                            if(index < array.length - 1){
                                                item.status.reps[item.status.reps.length-1].timer = item.status.reps[0].timer;
                                                item.status.reps[item.status.reps.length-1].currentTimer = item.status.reps[0].currentTimer;
                                            }
                                            return item;
                                        })                                       
                                    ]  
                                }
                            case TIMER_30:
                                rand0 = randomArray(focuses[0][0].length, 1);
                                rand1 = randomArray(focuses[0][1].length, 1);
                                rand2 = randomArray(focuses[0][2].length, 2);
                                return {
                                    ...state,
                                    currentType: MUSCLE,
                                    timer: action.options.time,
                                    selectedMuscleExercises: [
                                        focuses[0][0].filter((focus, index) => rand0.includes(index))
                                        .concat(focuses[0][1].filter((focus, index) => rand1.includes(index))).map((item, index, array) => {
                                            if(index < array.length - 1){
                                                item.status.reps[item.status.reps.length-1].timer = item.status.reps[0].timer;
                                                item.status.reps[item.status.reps.length-1].currentTimer = item.status.reps[0].currentTimer;
                                            }
                                            return item;
                                        }),
                                        focuses[0][2].filter((focus, index) => rand2.includes(index)).map((item, index, array) => {
                                            if(index < array.length - 1){
                                                item.status.reps[item.status.reps.length-1].timer = item.status.reps[0].timer;
                                                item.status.reps[item.status.reps.length-1].currentTimer = item.status.reps[0].currentTimer;
                                            }
                                            return item;
                                        })
                                    ]
                                } 
                            case TIMER_45:
                                rand0 = randomArray(focuses[0][0].length, 2);
                                rand1 = randomArray(focuses[0][1].length, 2);
                                rand2 = randomArray(focuses[0][2].length, 2);
                                return {
                                    ...state,
                                    currentType: MUSCLE,
                                    timer: action.options.time,
                                    selectedMuscleExercises: [
                                        focuses[0][0].filter((focus, index) => rand0.includes(index)).map((item, index, array) => {
                                            if(index < array.length - 1){
                                                item.status.reps[item.status.reps.length-1].timer = item.status.reps[0].timer;
                                                item.status.reps[item.status.reps.length-1].currentTimer = item.status.reps[0].currentTimer;
                                            }
                                            return item;
                                        }),
                                        focuses[0][1].filter((focus, index) => rand1.includes(index)).map((item, index, array) => {
                                            if(index < array.length - 1){
                                                item.status.reps[item.status.reps.length-1].timer = item.status.reps[0].timer;
                                                item.status.reps[item.status.reps.length-1].currentTimer = item.status.reps[0].currentTimer;
                                            }
                                            return item;
                                        }),
                                        focuses[0][2].filter((focus, index) => rand2.includes(index)).map((item, index, array) => {
                                            if(index < array.length - 1){
                                                item.status.reps[item.status.reps.length-1].timer = item.status.reps[0].timer;
                                                item.status.reps[item.status.reps.length-1].currentTimer = item.status.reps[0].currentTimer;
                                            }
                                            return item;
                                        })
                                    ]
                                }  
                        }
                    } else if(focuses.length === 2){
                        let rand11, rand12, rand21, rand22, rand31, rand32;
                        // eslint-disable-next-line default-case
                        switch(action.options.time){
                            case TIMER_15: 
                                rand11 = randomArray(focuses[0][0].length, 1);
                                rand12 = randomArray(focuses[1][0].length, 1);
                                return {
                                    ...state,
                                    currentType: MUSCLE,
                                    timer: action.options.time,
                                    selectedMuscleExercises: [
                                        focuses[0][0].filter((focus, index) => rand11.includes(index))
                                        .concat(focuses[1][0].filter((focus, index) => rand12.includes(index))).map((item, index, array) => {
                                            if(index < array.length - 1){
                                                item.status.reps[item.status.reps.length-1].timer = item.status.reps[0].timer;
                                                item.status.reps[item.status.reps.length-1].currentTimer = item.status.reps[0].currentTimer;
                                            }
                                            return item;
                                        })
                                    ]
                                }
                            case TIMER_30:
                                rand11 = randomArray(focuses[0][0].length, 1);
                                rand12 = randomArray(focuses[1][0].length, 1);
                                rand21 = randomArray(focuses[0][1].length, 1);
                                rand22 = randomArray(focuses[1][1].length, 1);
                                return {
                                    ...state,
                                    currentType: MUSCLE,
                                    timer: action.options.time,
                                    selectedMuscleExercises:    [ 
                                        focuses[0][0].filter((focus, index) => rand11.includes(index))
                                        .concat(focuses[1][0].filter((focus, index) => rand12.includes(index))).map((item, index, array) => {
                                            if(index < array.length - 1){
                                                item.status.reps[item.status.reps.length-1].timer = item.status.reps[0].timer;
                                                item.status.reps[item.status.reps.length-1].currentTimer = item.status.reps[0].currentTimer;
                                            }
                                            return item;
                                        }),
                                        focuses[0][1].filter((focus, index) => rand21.includes(index))
                                        .concat(focuses[1][1].filter((focus, index) => rand22.includes(index))).map((item, index, array) => {
                                            if(index < array.length - 1){
                                                item.status.reps[item.status.reps.length-1].timer = item.status.reps[0].timer;
                                                item.status.reps[item.status.reps.length-1].currentTimer = item.status.reps[0].currentTimer;
                                            }
                                            return item;
                                        })
                                    ]
                                } 
                            case TIMER_45:
                                rand11 = randomArray(focuses[0][0].length, 1);
                                rand12 = randomArray(focuses[1][0].length, 1);
                                rand21 = randomArray(focuses[0][1].length, 1);
                                rand22 = randomArray(focuses[1][1].length, 1);
                                rand31 = randomArray(focuses[0][2].length, 1);
                                rand32 = randomArray(focuses[1][2].length, 1);
                                return {
                                    ...state,
                                    currentType: MUSCLE,
                                    timer: action.options.time,
                                    selectedMuscleExercises:    [
                                        focuses[0][0].filter((focus, index) => rand11.includes(index))
                                        .concat(focuses[1][0].filter((focus, index) => rand12.includes(index))).map((item, index, array) => {
                                            if(index < array.length - 1){
                                                item.status.reps[item.status.reps.length-1].timer = item.status.reps[0].timer;
                                                item.status.reps[item.status.reps.length-1].currentTimer = item.status.reps[0].currentTimer;
                                            }
                                            return item;
                                        }),
                                        focuses[0][1].filter((focus, index) => rand21.includes(index))
                                        .concat(focuses[1][1].filter((focus, index) => rand22.includes(index))).map((item, index, array) => {
                                            if(index < array.length - 1){
                                                item.status.reps[item.status.reps.length-1].timer = item.status.reps[0].timer;
                                                item.status.reps[item.status.reps.length-1].currentTimer = item.status.reps[0].currentTimer;
                                            }
                                            return item;
                                        }),
                                        focuses[0][2].filter((focus, index) => rand31.includes(index))
                                        .concat(focuses[1][2].filter((focus, index) => rand32.includes(index))).map((item, index, array) => {
                                            if(index < array.length - 1){
                                                item.status.reps[item.status.reps.length-1].timer = item.status.reps[0].timer;
                                                item.status.reps[item.status.reps.length-1].currentTimer = item.status.reps[0].currentTimer;
                                            }
                                            return item;
                                        })
                                    ]
                                }  
                        }
                    }
                    break;
                }
                case CARDIO: {
                    let exercises = state.cardioExercises.filter(item => +item.exercise_duration == action.options.time/60);
                    let rand = randomArray(exercises.length, 1)[0];
                    return {
                        ...state,
                        timer: action.options.time,
                        selectedCardioExercises: exercises[rand]
                    }
                }
                case FULL_BODY: {
                    let exercises = state.fullBodyExercises.filter(item => +item.user_select_time == action.options.time/60);
                    let rand = randomArray(exercises.length, 1)[0];
                    let exercise = exercises[rand];
                    let sets = exercise.sets.map(set => {
                        return {
                            ...set,
                            exercises: [
                                ...set.exercises.map(item => {
                                    let status = {
                                        isRest: false,
                                        isStart: false, // for single exercise
                                        isComplete: false,
                                        current: 0,
                                        reps: []
                                    }
                                    for(let i = 0; i < set["sets-count"];i++){
                                        let rep = {
                                            activeRep: (i === 0) ? true : false,
                                            activeRest: false,
                                            timer: (set["rest-after-set"]) ? set["rest-after-set"] : 0,
                                            currentTimer: (set["rest-after-set"]) ? set["rest-after-set"] : 0,
                                        }
                                        status.reps.push(rep);
                                    }
                                    return {
                                        ...item,
                                        status: status
                                    }
                                })
                            ]
                        }
                    })
                    return {
                        ...state,
                        timer: exercise.workout_time*60,
                        selectedFullBodyExercises: {
                            ...exercise,
                            isStart: false,
                            sets: sets
                        }
                    }
                }
            }   
        // eslint-disable-next-line no-fallthrough
        case SET_STATUS:
            return {
                ...state,
                selectedMuscleExercises: [
                    ...state.selectedMuscleExercises.map(list => list.map(exercise => {
                        if(exercise.id == action.id){
                            exercise.status = action.status;
                        }
                        return exercise;
                    }))
                    
                ]
            }
        case START_FULLBODY:
            return {
                ...state,
                selectedFullBodyExercises: {
                    ...state.selectedFullBodyExercises,
                    isStart: true
                }
            }
        case START_TIMER:
            return {
               ...state,
                isStart: true,
                lastTimerUpdate: new Date() 
            }
        case STOP_TIMER:
            return {
               ...state,
                isStart: false,
                lastTimerUpdate: new Date() 
            }
        case STOP_FULLBODY:
            return {
                ...state,
                selectedFullBodyExercises: {
                    ...state.selectedFullBodyExercises,
                    isStart: false
                }
            }
        case SET_FULLBODY_STATUS:
            return {
                ...state,
                selectedFullBodyExercises: {
                    ...state.selectedFullBodyExercises,
                    sets: state.selectedFullBodyExercises.sets.map(set => {
                        return {
                            ...set,
                            exercises: set.exercises.map(exercise => {
                                if(exercise.id == action.id && set.id == action.set){
                                    exercise.status = action.status; 
                                }
                                return exercise
                            })
                        }
                    })
                }
            }
        default:
            return state;
    }
}