import arrow from './../../img/go_back.svg'
import img from './../../img/vibe.png'

import {connect} from 'react-redux';
import { useState, useEffect} from 'react'; 
import { useNavigate } from 'react-router-dom';

import { SET_VIBE, MUSCLE, CARDIO, FULL_BODY, SET_EXERCISES } from '../../constants/actionTypes';

function OptionVibe(props){

    const [state, setState] = useState({
        disable: true,
        vibeList: [
            {label: "Happy I made it in, go easy on me.",checked: false,value: "1",id: "vibe1"},
            {label: "Feeling good, here to work.",checked: false,value: "2",id: "vibe2"},
            {label: "Ready to go, let's push it!",checked: false,value: "3",id: "vibe3"},
        ]
    });

    const linkToPreviousPage = `/goal`;
    const navigate = useNavigate();  

    useEffect(() => {
        document.title = 'Vibe page';
        if(state.vibeList.some(item => item.value === props.vibe)){
            const newList = state.vibeList.map(item => {
                    if(item.value === props.vibe){
                        item.checked = true;
                    }
                    return item;
                })
            setState({...state, vibeList: newList, disable: false})
            }
    },[]);

    function goBack(){
        navigate(linkToPreviousPage);
    }

    function goNext(){
        props.onSelectExercise(props.options); 
        switch(props.options.exerciseType){
            case MUSCLE:
                navigate(`/muscle-start`); break;
            case CARDIO:
                navigate(`/cardio`); break;
            case FULL_BODY:
                navigate(`/body`); break;
            default:
                navigate(`/focus`);
        }
    }

    function vibeChange(e){
        const newList = state.vibeList.map(item => {
                if(item.value === e.target.value){
                    item.checked = true;
                } else {
                    item.checked = false;
                }
                return item;
            });
        setState({...state, vibeList: newList, disable: false});
        props.onChangeVibe(e.target.value);      
    }

    return(
        <>
        <img src={arrow} alt='arrow' onClick={goBack} className='go-Back'/>
        <div className="Vibe-body">
            <div className="Vibe-wrapper">
                <h2 className='Vibe-title'>Excellent, {props.options.goal}.<br></br> 
                What's the <span className="Vibe-span span">vibe</span> today?</h2>
                <div className='Vibe-selection'>   
                    {
                    state.vibeList.map((item, index) =>{
                        return(
                            <div className='Vibe-select' key={index} >
                                <input type='radio' name="vibe" id={item.id} className="Vibe-input" value={item.value} onChange={vibeChange} checked={item.checked}/>
                                <label htmlFor={item.id} className="Vibe-label"><p>{item.label}</p></label> 
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="Vibe-image">
                 <img src={img} alt='vibe' className='img'/>   
            </div>
            <button className='go-Next' onClick = {goNext} disabled={state.disable}>Finish</button>
         </div>   
         </>
    )
}
const mapStateToProps = state =>{
    return {
        vibe: state.option.options.vibe,
        options: state.option.options
    }    
}

const mapDispatchToProps = dispatch => ({
    onChangeVibe: (value) => {
        dispatch({
            type: SET_VIBE, value
        })
    },
    onSelectExercise: (options) => {
        dispatch({
            type: SET_EXERCISES, options
        })
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(OptionVibe); 